@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
  color: #565387;
}

.navbar {
  background: rgb(255, 255, 255);
  color: #565387;
  /* color:#f8f9fa */
}

.btn.btn-outline-primary {
  color: #565387;
  border-color: #565387;
}

.navbar-toggler {
  color: #565387;
}

.navbar-brand {
  font-size: 1.9rem;
  color: #565387;
}

.nav-item {
  font-size: 1.3rem;
  text-transform: capitalize;
  color: #565387;

}

.btn-primary {
  color: #fff;
  background-color: #565387;
  border-color: #565387;
}

.btn.btn-primary:hover {
  color: #fff;
  background-color :#565387;
  border-color: #565387;
}
.btn.btn-primary{
  color: #565387;
  background-color: #fff;
  border-color: #565387;
}


/* .btn.btn-outline-primary {
  color: #fff;
  background-color: #565387;
  border-color: #565387;
} */

.btn.btn-outline-primary:hover {
  color: #fff;
  background-color: #565387;
  border-color: #565387;
}



.nav-link {
  color: #565387;
}

.menu_active {
  font-weight: bold;
  border-bottom: 1px solid #565387;
  color: #565387;

}

.navbar a:hover,
#header .brand-name {
  color: #565387;
}

#header {
  width: 100%;
  height: 80vh;
  margin-top: 70px;
  color: #565387;

}

#header h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color: black;
}

#header h2 {
  color: #484848;
  font-size: 24px;
}

#header .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #565387;
  border: 2px solid #565387;
  text-decoration: none;
}

#header .btn-get-started:hover {
  background: #565387;
  color: #fff;
}

.header-img {
  text-align: right;
}

#header .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down {
  0% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

/* The alert message box */
.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.error {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

.secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}

.info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
/* The close button */
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
  color: black;
}
.hide {
  display: none;
}
